<template>
  <div
    class="row fade-left-element"
    v-bind:class="{ 'fade-left animate__animated animate__fadeInLeft ': isVisible }"
    ref="elementToObserve"
  >
    <div
      class="h-78 flex flex-column justify-center"
      :class="isSlideDivided ? 'col-sm-12 col-md-12 col-lg-8' : 'col-sm-12 col-md-12'"
    >
      <div class="slide-title w-2/3 font-bold" :class="isWhite? 'text-white': 'text-dark'">
        <!-- Empowering food delivery tech. -->
        {{title}}
      </div>
      <div class="slide-logo w-1/4 bg-white" v-if="slideLogo">
        <!-- <img :src="require('../../assets/images/'+ slideLogo + '')" class="w-100" alt=""> -->
        <image-item
          class="article-item__image"
          :source="require('../../assets/images/' + slideLogo + '')"
        />
      </div>
      <div
        class="slide-description w-2/3 sm:w-3/6"
        :class="[isWhite? 'text-white': 'text-dark', !slideLogo ? 'mt-8' : '']"
        v-if="description"
      >{{description}}</div>
      <div class="slide-left-button text-left md:mt-15 mt-11 pb-2" v-if="btnText">
        <base-button
          :href="btnPath"
          id="GetInTouchFour"
          :class="isWhite? 'btn-primary-white' : 'btn-primary'"
          v-bind:state="buttonState"
          aria-expanded="false"
        >{{btnText}}</base-button>
      </div>
    </div>

    <div
      class="col-sm-12 col-md-12 col-lg-4 text-center d-none d-lg-flex d-xl-flex"
      v-if="isSlideDivided || sideImage"
    >
      <div class="title-image ml-auto flex content-end">
        <!-- <img :src="require('../../assets/images/' + sideImage + '' )" alt=""> -->
        <image-item
          class="article-item__image"
          :source="require('../../assets/images/' + sideImage + '')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../BaseButton";
import ButtonState from "../../lib/button-state";
import ImageItem from "../ImageItem.vue";

export default {
  components: {
    BaseButton,
    ImageItem
  },
  data() {
    return {
      buttonState: ButtonState.Ready,
      isVisible: false
    };
  },

  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.5 // Adjust this threshold value as needed
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isVisible = true;
        }
      });
    }, options);

    observer.observe(this.$refs.elementToObserve);
  },
  props: {
    title: String,
    description: String,
    slideLogo: String,
    btnText: String,
    btnPath: String,
    isSlideDivided: Boolean,
    sideImage: String,
    isWhite: Boolean
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
